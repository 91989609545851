.App-logo {
  height: 10vmin;
  pointer-events: none;
}

.App-header {
  height: 5vh;
  padding: 0 20px;
  margin-bottom: 5px;
  background-color: #f6f3f2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: calc(8px + 0.8vmin);
  color: #282c34;
}

.App-header h1 {
  line-height: 0;
  font-size: 1.3rem;
}

.App-header ul {
  display: flex;
  list-style-type: none;
  align-items: center;
  margin: 0;
}

.App-header ul li {
  margin: 0 1em 0 0;
}

li a:visited {
  color: initial;
}

.App-header ul li a {
  text-decoration: none;
  list-style-type: none;
  color: #282c34;
}
.App-header li {
  cursor: pointer;
}
.App-header button {
  padding: 5px 10px;
  border: none;
  box-sizing: border-box;
  background-color: #407ed9;
  color: #fff;
  font-weight: 600;
  border-radius: 3px;
  margin: 5px 0;
}
.App-header button a {
  text-decoration: none;
}
.App-header button a:visited {
  color: #fff;
}
.container {
  height: 100vh;
}
.App {
  flex: 1 0 auto;
}

footer {
  flex-shrink: 0;
}

.device .popup-inner {
  left: 5%;
  right: 5%;
  top: 10%;
  bottom: auto;
}

#device-message {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  line-height: 2;
  text-align: center;
}

@media screen and (min-width: 1025px) {
  .device {
    display: none;
  }
}
