.Form-h1 {
  color: #407ed9;
}

.Form-wrapper {
  width: 70%;
  max-width: 1000px;
  margin: 0 auto;
  text-align: left;
}

.Form input[type='text'],
input[type='email'],
input[type='password'],
input[type='submit'] {
  padding: 10px;
  margin: 5px 0;
  width: 50%;
  border: 2px solid #bababa;
  box-sizing: border-box;
}

.Form label {
  width: 50%;
}

.Form .button {
  border: none;
  margin-top: 10px;
  margin-bottom: 0;
  background-color: #407ed9;
  color: #fff;
  font-weight: 600;
  border-radius: 5px;
}
.Form .button-secondary {
  border: 2px solid #bababa;
  background-color: #f6f3f2;
  color: #bababa;
}
.case-form {
  width: 55%;
  height: 25vh;
  margin: 0 auto;
}
.case-form .input-wrapper {
  background-color: lightgray;
  padding: 2% 5%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto 2% auto;
}
.radio-wrapper {
  padding: 10px;
  display: flex;
  width: 50%;
}
.question-header {
  text-decoration: underline;
  font-weight: bold;
  line-height: 0;
}
.question {
  text-align: left;
}
.question label {
  line-height: 150%;
}
.question input {
  margin-left: 0;
}
button,
.button {
  cursor: pointer;
}
.case-form .button:disabled,
.case-form .button[disabled] {
  background-color: #cccccc;
  color: black;
  box-sizing: border-box;
}
.case-form .button {
  padding: 5px 10px;
  margin: 0 25px;
  border: none;
  box-sizing: border-box;
  background-color: #407ed9;
  color: #fff;
  font-weight: 600;
  border-radius: 5px;
}
.button.button-case-form {
  margin-top: 0;
}
.error-message {
  background-color: pink;
  width: 50%;
  line-height: 200%;
  text-align: center;
  margin: 10px auto;
  margin-bottom: 5px;
}
.Form .error-message {
  margin: 0;
}
.case-error {
  margin: 0 auto 5px auto;
}
.success-message {
  background-color: yellow;
  width: 50%;
  line-height: 200%;
  text-align: center;
  margin-bottom: 5px;
}
.forgotten-password {
  margin: 30px 0 30px 0;
  width: 50%;
}
.forgotten-password input {
  width: 100%;
}
.forgotten-password .Form .success-message {
  width: 100%;
  margin-top: 10px;
}
.forgotten-password .Form .error-message {
  width: 100%;
  margin-top: 10px;
}
.counter {
  font-size: 12px;
}
.gallery {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 80%;
  max-height: 65vh;
  margin: 0 auto;
}

.gallery-image-one {
  width: 100%;
  position: relative;
}
.gallery-image-two {
  width: 50%;
  position: relative;
}
.gallery-image-three {
  width: 33%;
  position: relative;
}
.gallery-image-four-plus {
  width: 25%;
  position: relative;
}
.gallery-image {
  max-width: 95%;
  cursor: pointer;
}
.gallery-image-one .gallery-image {
  max-height: 60vh;
}
.gallery-image-two .gallery-image {
  max-height: 60vh;
}
.gallery-image-three .gallery-image {
  max-height: 60vh;
}
.gallery-image-four-plus .gallery-image {
  max-height: 30vh;
}

.gallery-cover {
  z-index: 2;
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.case-page {
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
