button {
  cursor: pointer;
}

.instructions {
  background-color: white;
  color: #5c3927;
  border: 4px solid #5c3927;
  border-radius: 15px;
  text-align: center;
  padding: 3%;
  min-height: 15vh;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
}
.popup-inner .error-message {
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}
.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 4;
}
.popup-slider {
  position: fixed;
  width: 100%;
  top: 5vh;
  z-index: 3;
}
.popup-inner {
  position: absolute;
  font-size: 14px;
  line-height: 1.4;
  font-family: 'Georgia', 'Times new roman';
  padding-bottom: 20px;
  padding-top: 10px;
  text-align: left;
  left: 25%;
  right: 25%;
  top: 15%;
  bottom: 15%;
  border: 1px solid gray;
  margin: auto;
  background: white;
  overflow: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.popup-inner p {
  margin: 5%;
}
.closing-x-div {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-right: 15px;
  box-sizing: border-box;
}
.popup-inner .svg-inline--fa {
  height: 2em;
  width: auto;
  text-align: right;
}
button {
  margin: 0 auto;
}
.buttonDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}
