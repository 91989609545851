.slider {
  position: relative;
  height: 65vh;
  width: 100vw;
  margin: 0 auto;
  overflow: hidden;
}
.slider-img {
  max-width: 100%;
  max-height: 100%;
}
.slider-div {
  width: 100%;
}
.slider-cover {
  z-index: 2;
  position: absolute;
  height: 65vh;
  width: 100%;
  top: 0;
}

.dotStyle {
  padding: 5px;
  margin-right: 5px;
  border-radius: 50%;
}
.gray {
  background-color: lightgray;
}
.black {
  background-color: #407ed9;
}
.dotsStyle {
  position: absolute;
  bottom: 3%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrowStyle {
  position: absolute;
  cursor: pointer;
  z-index: 5;
  top: 40%;
  height: 40px;
}
.right {
  right: 5%;
}
.left {
  left: 5%;
}

.closing-x-slider .closing {
  position: absolute;
  cursor: pointer;
  z-index: 5;
  right: 5%;
  top: 2%;
  height: 40px;
  width: 40px;
  color: gray;
}

.arrowStyle .iconStyle {
  height: 100%;
  width: auto;
  color: #407ed9;
}
